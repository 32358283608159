import React, { useState, useEffect } from 'react'
import { Switch } from 'antd'
import classNames from 'classnames'

import { InputQuizSettings } from './styles'
import { checkInput } from 'utils/helper'
import { get } from 'lodash'
import { DOMAIN } from '../../../../../../routes'
import { makeSelectDomain } from '../../../../../Home/selectors'
import { useSelector } from 'react-redux'

const QuizSettings = ({ formik }) => {
  const [allowPassingGrade, setAllowPassGrade] = useState(false)
  const [allowTimer, setAllowTimer] = useState(false)
  const domain = useSelector(makeSelectDomain())
  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA

  useEffect(() => {
    if (formik.values.quiz_pass_rate) {
      setAllowPassGrade(true)
    } else {
      setAllowPassGrade(false)
    }
  }, [formik.values.quiz_pass_rate])
  useEffect(() => {
    if (formik.values.is_show_timer) {
      setAllowTimer(true)
    } else {
      setAllowTimer(false)
    }
  }, [formik.values.is_show_timer])

  const handleChangeInputNumber = (e) => {
    const value = e.currentTarget.value

    if (checkInput(e) && checkPercent(value)) {
      formik.setFieldValue('quiz_pass_rate', value)
    }
  }
  const checkPercent = (percent) => {
    return Number(percent) <= 100
  }
  const handleAllowPassingGrade = (checked) => {
    setAllowPassGrade(checked)
    if (!checked) {
      formik.setFieldValue('quiz_pass_rate', 0)
    }
  }

  const handleAllowTimer = (checked) => {
    if (!checked) {
      formik.setFieldValue('timer_hours', 0)
      formik.setFieldValue('timer_minutes', 0)
      formik.setFieldValue('timer_seconds', 0)
    }
    setAllowTimer(checked)
    formik.setFieldValue('is_show_timer', checked)
  }

  const handleChangeTimer = (e, fieldName) => {
    const value = e.currentTarget.value
    const numericValue = Number(value)

    const currentHours = Number(formik.values.timer_hours) || 0
    const currentMinutes = Number(formik.values.timer_minutes) || 0
    const currentSeconds = Number(formik.values.timer_seconds) || 0

    if (fieldName === 'hours') {
      const totalSeconds =
        numericValue * 3600 + currentMinutes * 60 + currentSeconds
      if (totalSeconds <= 86400 && numericValue <= 24) {
        formik.setFieldValue('timer_hours', numericValue)
      } else {
        return
      }
    }

    if (fieldName === 'minutes') {
      // Calculate total time in seconds
      const totalSeconds =
        currentHours * 3600 + numericValue * 60 + currentSeconds

      if (totalSeconds <= 86400 && numericValue <= 59) {
        formik.setFieldValue('timer_minutes', numericValue)
      } else {
        formik.setFieldValue('timer_minutes', 0)
      }
    }

    if (fieldName === 'seconds') {
      // Calculate total time in seconds
      const totalSeconds =
        currentHours * 3600 + currentMinutes * 60 + numericValue

      if (totalSeconds <= 86400 && value <= 59) {
        formik.setFieldValue('timer_seconds', numericValue)
      } else {
        formik.setFieldValue('timer_seconds', 0)
      }
    }
  }
  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault()
    }
  }
  return (
    <div className="lessons__tags mgB-45">
      <div
        className="add-course__switch switch_wrap"
        style={{ marginBottom: 2 }}
      >
        <Switch
          size={25}
          checked={allowPassingGrade}
          onChange={(checked) => handleAllowPassingGrade(checked)}
        />
        <div
          className={classNames({
            'quiz-addnew__text': allowPassingGrade,
            'add-course__switch__label': !allowPassingGrade,
          })}
          style={{ marginLeft: 6 }}
        >
          This quiz requires a passing grade
        </div>
      </div>
      {allowPassingGrade && (
        <>
          <div style={{ paddingLeft: 45 }}>Passing Grade</div>
          <InputQuizSettings>
            <input
              value={allowPassingGrade ? formik.values.quiz_pass_rate : ''}
              onChange={
                allowPassingGrade ? (e) => handleChangeInputNumber(e) : () => {}
              }
              type="text"
            />
            <div>%</div>
          </InputQuizSettings>
        </>
      )}
      <div className="add-course__switch switch_wrap" style={{ marginTop: 20 }}>
        <Switch
          size={25}
          checked={formik.values.show_quiz_only}
          onChange={(checked) =>
            formik.setFieldValue('show_quiz_only', checked)
          }
        />
        <div
          className={classNames({
            'quiz-addnew__text': formik.values.show_quiz_only,
            'add-course__switch__label': !formik.values.show_quiz_only,
          })}
          style={{ marginLeft: 6 }}
        >
          Hide lesson and show quiz only
        </div>
      </div>
      <div className="add-course__switch switch_wrap" style={{ marginTop: 20 }}>
        <Switch
          size={25}
          checked={!formik.values.is_show_answer}
          onChange={(checked) => {
            formik.setFieldValue('is_show_answer', !checked)
          }}
        />
        <div
          className={classNames({
            'quiz-addnew__text': !formik.values.is_show_answer,
            'add-course__switch__label': formik.values.is_show_answer,
          })}
          style={{ marginLeft: 6 }}
        >
          Automark and display the MCQ answers to students
        </div>
      </div>

      {wfaDomain && (
        <>
          <div
            className="add-course__switch switch_wrap"
            style={{ marginTop: 20 }}
          >
            <Switch
              size={25}
              checked={formik.values.is_correct_answer}
              onChange={(checked) =>
                formik.setFieldValue('is_correct_answer', checked)
              }
            />
            <div
              className={classNames({
                'quiz-addnew__text': formik.values.is_correct_answer,
                'add-course__switch__label': !formik.values.is_correct_answer,
              })}
              style={{ marginLeft: 6 }}
            >
              Answer must be correct to proceed to the next question
            </div>
          </div>
          <div
            className="add-course__switch switch_wrap"
            style={{ marginTop: 20 }}
          >
            <Switch
              size={25}
              checked={formik.values.is_show_timer}
              onChange={(checked) => handleAllowTimer(checked)}
            />
            <div
              className={classNames({
                'quiz-addnew__text': !formik.values.is_show_timer,
                'add-course__switch__label': formik.values.is_show_timer,
              })}
              style={{ marginLeft: 6 }}
            >
              Quiz time limit
            </div>
          </div>
          {allowTimer && (
            <div style={{ display: 'flex' }}>
              <div style={{ marginLeft: '50px' }}>
                <div>Hours</div>
                <input
                  value={allowTimer ? formik.values.timer_hours : 0}
                  onChange={
                    allowTimer ? (e) => handleChangeTimer(e, 'hours') : () => {}
                  }
                  onKeyPress={allowTimer ? handleKeyPress : null}
                  type="text"
                  style={{
                    width: '100px',
                    height: '32px',
                    border: '1px solid #e7ebf4',
                    padding: '8px',
                    borderRadius: '4px',
                    outline: 'none',
                    textAlign: 'right',
                    fontSize: '14px',
                    letterSpacing: '0.03em',
                    color: 'rgba(0, 0, 0, 0.65)',
                  }}
                />
              </div>
              <div style={{ margin: '25px 5px', fontWeight: 'bold' }}>:</div>
              <div>
                <div>Minutes</div>
                <input
                  value={allowTimer ? formik.values.timer_minutes : 0}
                  onChange={
                    allowTimer
                      ? (e) => handleChangeTimer(e, 'minutes')
                      : () => {}
                  }
                  onKeyPress={allowTimer ? handleKeyPress : null}
                  type="text"
                  style={{
                    width: '100px',
                    height: '32px',
                    border: '1px solid #e7ebf4',
                    padding: '8px',
                    borderRadius: '4px',
                    outline: 'none',
                    textAlign: 'right',
                    fontSize: '14px',
                    letterSpacing: '0.03em',
                    color: 'rgba(0, 0, 0, 0.65)',
                  }}
                />
              </div>
              <div style={{ margin: '25px 5px', fontWeight: 'bold' }}>:</div>
              <div>
                <div>Seconds</div>
                <input
                  value={allowTimer ? formik.values.timer_seconds : 0}
                  onChange={
                    allowTimer
                      ? (e) => handleChangeTimer(e, 'seconds')
                      : () => {}
                  }
                  onKeyPress={allowTimer ? handleKeyPress : null}
                  type="text"
                  style={{
                    width: '100px',
                    height: '32px',
                    border: '1px solid #e7ebf4',
                    padding: '8px',
                    borderRadius: '4px',
                    outline: 'none',
                    textAlign: 'right',
                    fontSize: '14px',
                    letterSpacing: '0.03em',
                    color: 'rgba(0, 0, 0, 0.65)',
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default QuizSettings
