import { get, isEmpty } from 'lodash'
import { takeLatest, put, call, all, select, delay } from 'redux-saga/effects'
import { stringify } from 'query-string'

import * as navigation from 'utils/navigation'
import * as constants from './constants'
import * as modalActions from 'container/Modal/actions'
import * as actions from './actions'
import config from 'src/global-config'
import request from 'utils/request'
import { showSuccess, showError } from '../../utils/notification'
import {
  validSchedules,
  availableSeats,
  calculateSeatsLeft,
  mapListUserAnswerToObj,
} from 'utils/helper'
import {
  loadCourse,
  loadSlides,
  loadSlideDetails,
  loadListCourse,
  loadCourseSuccess,
  loadDomain,
} from '../Home/actions'
import { makeSelectQuestions, makeSelectSelectedSection } from './selectors'
import {
  hideGlobalLoading,
  showGlobalLoading,
  hideFormSchedule,
  hideFormOutcomeModal,
  showFormUserModal,
} from '../Modal/actions'
import {
  makeSelectSlides,
  makeSelectSlideDetails,
  makeSelectCourse,
  makeSelectSelectedSlide,
  makeSelectDomain,
} from '../Home/selectors'
import dayjs from 'dayjs'
import { isScheduleAlwaysAvailable } from 'utils/helper'
import { getProfile } from 'utils/profileUtils'
import { makeSelectCurrentUser } from '../Auth/selectos'
import axiosInstance from '../../utils/axiosInstance'

const addCourseToClient = async (clientID, courseID) => {
  try {
    await axiosInstance.put('/api/clients/channels', {
      client_id: clientID,
      channel_id: courseID,
    })
  } catch (error) {
    console.log('error', error)
  }
}
function* onCreateCourse(action) {
  const isSaveAndGo = action.saveGo
  const setFilter = action.setFilter
  const id = action.params.id || ''
  const isAdminClient = action.isAdminClient || false
  const clientID = localStorage.getItem('clientID') || null

  let requestURL = `${config.baseUrl}/api/lms/courses/channels`

  if (id) {
    requestURL = `${config.baseUrl}/api/lms/courses/channels/${id}`
  }
  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: id ? 'PATCH' : 'POST',
      body: JSON.stringify(action.params),
    })

    const errors = get(res, 'errors')

    if (errors) {
      yield put(modalActions.hideGlobalLoading())
      if (id) {
        return showError(
          get(errors, 'errors.name', 'Update Course Failed!').toString()
        )
      } else {
        return showError(
          get(errors, 'errors.name', 'Create Course Failed!').toString()
        )
      }
    }

    const courseId = get(res, 'data.id', null)

    if (isAdminClient && !id && courseId) {
      addCourseToClient(+clientID, courseId)
    }

    if (id) {
      showSuccess('Update Course Successful!')
    } else {
      showSuccess('Create Course Successful!')
      navigation.navigate(`/admin/course/channel/${courseId}`)
      yield put(loadSlides(courseId, true))
    }

    yield put(loadCourse(courseId))

    if (isSaveAndGo) {
      setFilter('lessons')
    }
    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    yield put(modalActions.hideGlobalLoading())
    console.log('err', err)
  }
}

function* onCreateSection(action) {
  const slides = yield select((state) => makeSelectSlides()(state))
  const id = action.idCourse || ''
  const sequenceUpdated = action.params.sequence
  let sequence
  if (id && sequenceUpdated >= 0) {
    sequence = sequenceUpdated
  } else if (slides.length === 0) {
    sequence = 0
  } else {
    sequence = slides[slides.length - 1].sequence + 1
  }

  let requestURL = `${config.baseUrl}/api/lms/courses/channels/${id}/slides`

  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify({
        ...action.params,
        sequence,
        channel_id: +id,
      }),
    })

    if (get(res, 'errors')) {
      showError('Create Section Failed!')
      return yield put(modalActions.hideGlobalLoading())
    }
    if (get(res, 'data.id', null)) {
      if (id) {
        showSuccess('Update Section Successful!')
      } else {
        showSuccess('Create Section Successful!')
      }
      yield put(loadSlides(id, true))
      yield put(actions.setSelectedSection({ sequence }))
    }
    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    yield put(modalActions.hideGlobalLoading())
    console.log('err', err)
  }
}

function* onCreateLesson(action) {
  const idSlide = action.params.id
  let idCourse = action.idCourse
  const needToLoading = action.needToLoading
  // add new course
  const selectedSection = yield select((state) =>
    makeSelectSelectedSection()(state)
  )
  if (needToLoading) {
    yield put(modalActions.showGlobalLoading())
  }

  const slides = yield select((state) => makeSelectSlides()(state))
  if (!selectedSection && !idSlide) {
    yield put(modalActions.hideGlobalLoading())
    return showError('Please select section to create lesson!')
  }

  let sequence = get(selectedSection, 'sequence')
  let sub_sequence = get(slides, `[${sequence}].items.length`)
  let requestURL = `${config.baseUrl}/api/lms/courses/channels/${idCourse}/slides`
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(
        idSlide
          ? { ...action.params }
          : { ...action.params, sequence, sub_sequence: sub_sequence - 1 }
      ),
    })
    const successId = get(res, 'data.id', null)

    if (!successId) {
      yield put(modalActions.hideGlobalLoading())
      if (idSlide) {
        showError('Update Lesson Failed')
      } else {
        showError('Create Lesson Failed')
      }
      return
    }

    if (idSlide) {
      if (needToLoading) {
        showSuccess('Update Lesson Successful!')
      }

      yield put(loadSlideDetails({ id: successId }))
    } else {
      showSuccess('Create Lesson Successful!')
      action.resetForm()
    }

    yield put(loadSlides(idCourse, true, successId))
    if (needToLoading) {
      yield put(modalActions.hideGlobalLoading())
    }
  } catch (err) {
    if (needToLoading) {
      yield put(modalActions.hideGlobalLoading())
    }
    console.log('err', err)
  }
}

function* onCreateQuiz(action) {
  const name = action.params.name || ''
  const old_question_ids = action.params.old_question_ids || []
  const questions = action.params.questions || []
  const idCourse = action.params.idCourse
  const show_quiz_only = action.params.show_quiz_only
  const is_show_answer = action.params.is_show_answer
  const is_correct_answer = action.params.is_correct_answer
  const timer = action.params.timer

  const quizPassRate = parseInt(action.params.quizPassRate)
  const slideDetails = yield select((state) => makeSelectSlideDetails()(state))
  const requestURL = `${config.baseUrl}/api/lms/courses/channels/${idCourse}/slides`
  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(
        isEmpty(old_question_ids)
          ? {
              ...slideDetails,
              show_quiz_only,
              is_show_answer,
              is_correct_answer,
              quiz_pass_rate: quizPassRate,
              name: name,
              questions,
              timer,
            }
          : {
              ...slideDetails,
              quiz_pass_rate: quizPassRate,
              show_quiz_only,
              is_correct_answer,
              is_show_answer,
              name: name,
              old_question_ids,
              questions: [],
              timer,
            }
      ),
    })
    const id = get(res, 'data.id', 0)
    const errors = get(res, 'errors', 0)

    if (errors) {
      yield put(modalActions.hideGlobalLoading())
      return showError('Create or Update Quiz Failed!')
    }

    showSuccess('Create or Update Quiz Success!')
    yield put(loadSlides(idCourse, true, id))
    yield put(loadSlideDetails({ id: id }))
    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    yield put(modalActions.hideGlobalLoading())
    console.log('err', err)
  }
}

function* onLoadTags(action) {
  const requestURL = `${config.baseUrl}/api/lms/courses/channels/tags`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data)) {
      const options = res.data.map((option) => ({
        ...option,
        value: option.name,
      }))

      yield put(actions.loadTagsSuccess(options))
    }
  } catch (err) {
    console.log('err', err)
    yield put(hideGlobalLoading())
  }
}

function* onLoadSlideTags(action) {
  const requestURL = `${config.baseUrl}/api/lms/courses/slides/tags`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data)) {
      const options = res.data.map((option) => ({
        ...option,
        value: option.name,
      }))

      yield put(actions.loadSlideTagsSuccess(options))
    }
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onLoadPaymentStatus(action) {
  const channelId = action.params.channel_id
  const claimId = action.params.claim_id
  const nric = action.params.nric
  const requestURL = `${config.baseUrl}/api/payments/sfc/claims?channel_id=${channelId}&claim_id=${claimId}&nric=${nric}`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    const data = get(res, 'data.data')
    yield put(actions.loadPaymentStatusSuccess(data))
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onLoadCourseType(action) {
  const requestURL = `${config.baseUrl}/api/lms/courses/course-types`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(actions.LoadCourseTypeSuccess(res.data))
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onCreateZoomLink(action) {
  const requestURL = `${config.baseUrl}/api/notifications/zooms`
  const zoom = action.params.zoom
  const schedule = action.params.schedule
  const password = action.params.password
  const meeting_option = action.params.meeting_option
  const meeting_option_2 = action.params.meeting_option_2

  const zoomId = get(zoom, 'value', '')
  const course = yield select((state) => makeSelectCourse()(state))
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({
        name: course.name,
        zoom_id: zoomId,
        password,
        join_before_host: meeting_option,
        waiting_room: meeting_option_2,
      }),
    })
    if (res?.errors?.code) {
      yield put(hideGlobalLoading())
      return showError(res?.errors?.message)
    }
    const id = get(res, 'data.id')
    const joinUrl = get(res, 'data.join_url', '')
    const startUrl = get(res, 'data.start_url', '')
    const conferenceUrl = `${config.conferenceUrl}/`
    if (joinUrl) {
      showSuccess('Create Zoom Link Success')
      yield put(actions.createZoomLinkSuccess(joinUrl))
      yield put(
        actions.saveZoomLink({
          joinUrl,
          startUrl,
          schedule,
          conferenceUrl,
          meetingId: id,
          zoomId,
          meeting_option,
          meeting_option_2,
          password,
        })
      )
    }
    yield put(hideGlobalLoading())
  } catch (err) {
    console.log('err', err)
    yield put(hideGlobalLoading())
  }
}

function* onSaveZoomLink(action) {
  const joinUrl = action.params.joinUrl || ''
  const conferenceUrl = action.params.conferenceUrl || ''
  const startUrl = action.params.startUrl || ''
  const schedule = action.params.schedule || {}
  const meetingId = action.params.meetingId
  const zoomId = action.params.zoomId
  const password = action.params.password
  const meeting_option = action.params.meeting_option
  const meeting_option_2 = action.params.meeting_option_2

  const course = yield select((state) => makeSelectCourse()(state))
  const requestURL = `${config.baseUrl}/api/lms/courses/channels/${course.id}/meetings`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify({
        url: joinUrl,
        start_url: startUrl,
        schedule_id: get(schedule, 'id', ''),
        conference_url: conferenceUrl,
        meeting_id: meetingId + '',
        zoom_id: zoomId,
        join_before_host: meeting_option,
        waiting_room: meeting_option_2,
        password: password,
      }),
    })

    yield put(loadCourse(course.id))
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onSetGlobalCourse(action) {
  const is_published = action.params.is_published
  const course = yield select((state) => makeSelectCourse()(state))
  if (!course) {
    return showError('You need to create a course first!')
  }

  let requestURL = `${config.baseUrl}/api/lms/courses/channels/${course.id}`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PATCH',
      body: JSON.stringify({
        ...course,
        is_published,
        prices: [],
        last_published: dayjs().format(),
      }),
    })

    const data = get(res, 'data.id', null)
    if (data) {
      if (is_published) {
        showSuccess('Published course')
      } else {
        showSuccess('Unpublished course')
      }
    }

    yield put(loadCourse(course.id))
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onLoadListUserAnswer(action) {
  const selectedSlide = yield select((state) =>
    makeSelectSelectedSlide()(state)
  )
  const schedule_id = action.params
  let requestURL = `${config.baseUrl}/api/lms/courses/slides/${selectedSlide.id}/answers`

  if (schedule_id || schedule_id === 0) {
    requestURL = `${config.baseUrl}/api/lms/courses/slides/${selectedSlide.id}/answers?schedule_id=${schedule_id}&per_page=1000&ignore_per_page=true`
  }
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    const data = mapListUserAnswerToObj(res.data)
    yield put(actions.loadListUserAnswerSuccess(data))
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onSetAnswerCompetent(action) {
  const id = action.params.id
  const schedule = action.params.schedule
  const competent = action.params.competent
  const remark = action.params.remark
  const preventReload = action.params.preventReload
  let requestURL = `${config.baseUrl}/api/lms/courses/slides/answers/${id}`
  yield put(showGlobalLoading())
  try {
    yield call(request, requestURL, {
      method: 'PATCH',
      body: JSON.stringify({ competent, remark }),
    })

    yield put(actions.loadListUserAnswer(get(schedule, 'value', 0)))
    if (!preventReload) {
      yield put(hideGlobalLoading())
    }
  } catch (err) {
    if (!preventReload) {
      yield put(hideGlobalLoading())
    }
    console.log('err', err)
  }
}

function* onMarkAnswerCompetent(action) {
  const currentUser = action.params.currentUser
  const selectedSchedule = action.params.selectedSchedule
  const user_id = action.params.user_id
  const slide_id = action.params.slide_id
  const competent = action.params.competent
  const percent_quiz_pass = action.params.percent_quiz_pass + ''
  const total_passed = parseInt(action.params.total_passed)

  let requestURL = `${config.baseUrl}/api/lms/courses/slides/${slide_id}/user-answers`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify({
        user_id,
        competent,
        create_uid: get(currentUser, 'uid', 0),
        percent_quiz_pass,
        total_passed,
      }),
    })

    if (res.data.id) {
      showSuccess('Marking Submitted!')
      yield put(actions.loadListUserAnswer(get(selectedSchedule, 'value', 0)))
    }
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onCreateAccountStripe(action) {
  const checked = get(action, 'params.checked', null)
  const id = get(action, 'params.id', '')
  const redirect = get(action, 'params.redirect', '')
  let requestURL = `${config.baseUrl}/api/users/${id}/payments`
  yield put(showGlobalLoading())
  const body = {}
  if (checked !== null) {
    body.charges_enabled = checked
  }
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(body),
    })

    const chargesEnabled = get(res, 'charges_enabled', false)
    console.log('res', res)
    console.log('chargesEnabled', chargesEnabled)

    if (redirect) {
      return navigation.navigate(`/admin/settings?route=payments`)
    }

    if (res.data.account_link_url && !chargesEnabled) {
      window.location.replace(res.data.account_link_url)
    }

    yield put(loadDomain())
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onGetAccountStripe(action) {
  let requestURL = `${config.baseUrl}/api/companies`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (res.data.account_link_url && res.data.account_id) {
      yield put(actions.getAccountStripeSuccess(res.data))
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onAddSchedule(action) {
  const selectedCourse = yield select((state) => makeSelectCourse()(state))
  const id = action.params.id || ''
  const page = action.params.page || ''
  const newCourseId = action.params.newCourseId
  const handleResetField = action.handleResetField

  let requestURL = `${config.baseUrl}/api/lms/courses/channels/${get(
    selectedCourse,
    'id',
    ''
  )}/schedules`

  if (newCourseId) {
    requestURL = `${config.baseUrl}/api/lms/courses/channels/${newCourseId}/schedules`
  }
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(action.params),
    })

    const result = get(res, 'data.id')
    const message = get(res, 'errors.message', '')
    const code = get(res, 'errors.code', '')
    if (code === 400 || message === 'Duplicated Class Code') {
      yield put(hideGlobalLoading())
      return showError('Class code exists already, please use another code')
    }

    if (!result) {
      yield put(hideGlobalLoading())
      return showError('Update Or Add Schedule Failed!')
    }

    yield put(hideFormSchedule())
    const isAdmin = true
    yield put(
      actions.getSchedules(
        newCourseId ? newCourseId : selectedCourse.id,
        isAdmin,
        page
      )
    )

    if (id) {
      showSuccess('Update Schedule Success')
    } else {
      showSuccess('Add Schedule Success')
    }

    if (typeof handleResetField === 'function') handleResetField()
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

export const getStudentEnroll = async (idSchedule, idCourse) => {
  const requestURL = `${config.baseUrl}/api/lms/courses/channels/${idCourse}/partners?schedule_id=${idSchedule}&per_page=1000&ignore_per_page=true`
  try {
    const res = await fetch(requestURL, { method: 'GET' })
    return res.json()
  } catch (err) {
    console.log('err', err)
  }
}

function* onGetSchedules(action) {
  const isAdmin = action.isAdmin || false
  const needToLoadSize = action.needToLoadSize
  const needToLoadAll = action.needToLoadAll
  const page = action.page

  let requestURL = `${config.baseUrl}/api/lms/courses/channels/${action.params}/schedules?order_by=id%20desc`
  if (page) {
    requestURL += `&page=${page}&per_page=15`
  }
  if (needToLoadAll) {
    requestURL += `&per_page=1000&ignore_per_page=true`
  }
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data) && res.data.length >= 0) {
      const schedules = isAdmin ? res.data : validSchedules(res.data)
      if (needToLoadSize) {
        const requests = []
        for (let i = 0; i < schedules.length; i++) {
          const idSchedule = schedules[i].id
          requests.push(call(getStudentEnroll, idSchedule, action.params))
        }
        const data = yield all(requests)

        for (let i = 0; i < schedules.length; i++) {
          const studentEnroll = get(data, `[${i}].data.length`)
          const maxSizeSchedule = get(schedules, `[${i}].max_size`, 0)
          const seats = calculateSeatsLeft(studentEnroll, maxSizeSchedule)
          const isAvailable = availableSeats(maxSizeSchedule, seats)
          const isAlwaysAvailable = isScheduleAlwaysAvailable(
            get(schedules, `[${i}].start_date`, ''),
            get(schedules, `[${i}].end_date`, '')
          )
          schedules[i].seats = seats
          schedules[i].available = isAvailable
          schedules[i].isAlwaysAvailable = isAlwaysAvailable
        }
      }

      yield put(actions.getSchedulesSuccess(schedules))
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onDeleteCourse(action) {
  let requestURL = `${config.baseUrl}/api/lms/courses/channels/${action.params}`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'DELETE',
    })

    yield put(loadListCourse({ admin: true, filter: 'sequence' }))
    yield put(loadCourseSuccess(null))
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
  }
}

function* onDeleteSchedule(action) {
  const selectedCourse = yield select((state) => makeSelectCourse()(state))
  const page = action.page
  let requestURL = `${config.baseUrl}/api/lms/courses/schedules/${action.params}`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'DELETE',
    })

    if (selectedCourse && selectedCourse.id) {
      yield put(actions.getSchedules(selectedCourse.id, true, page))
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onDeleteLesson(action) {
  const selectedCourse = yield select((state) => makeSelectCourse()(state))
  let requestURL = `${config.baseUrl}/api/lms/courses/slides/${action.params}`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'DELETE',
    })

    if (selectedCourse && selectedCourse.id) {
      yield put(loadSlides(selectedCourse.id, true))
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

// CRUD learning_outcome

function* onLoadListLearningOutcome(action) {
  const domain = yield select((state) => makeSelectDomain()(state))

  const domainId = get(domain, 'id', '')
  let requestURL = `${config.baseUrl}/api/websites/${domainId}/learning-outcome?order_by=id%20asc`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (res.data) {
      yield put(actions.loadListLearningOutcomeSuccess(res.data))
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onCreateLearningOutcome(action) {
  const domain = yield select((state) => makeSelectDomain()(state))
  const needToLoadSlides = action.needToLoadSlides
  const selectedSchedule = action.selectedSchedule
  const id = action.idCourse

  let requestURL = `${config.baseUrl}/api/websites/${get(
    domain,
    'id',
    ''
  )}/learning-outcome`

  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(action.params),
    })

    if (res.data) {
      yield put(actions.loadListLearningOutcome())
    }
    if (needToLoadSlides) {
      yield put(loadSlides(id, false, null, get(selectedSchedule, 'value', '')))
    }
    yield put(hideFormOutcomeModal())
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onDeleteLearningOutcome(action) {
  let requestURL = `${config.baseUrl}/api/lms/courses/learning-outcome/${action.params}`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'DELETE',
    })

    yield put(actions.loadListLearningOutcome())
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

// CRUD assessment

function* onLoadAssessment(action) {
  let requestURL = `${config.baseUrl}/api/lms/courses/learning-outcome/${action.params}/assessment-criteria`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (res.data) {
      yield put(actions.loadAssessmentSuccess(res.data))
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onCreateAssessment(action) {
  const questions = yield select((state) => makeSelectQuestions()(state))
  const question = action.question
  const id = action.params.id
  const slideDetails = yield select((state) => makeSelectSlideDetails()(state))
  const outComeId = action.params.outComeId || ''
  let requestURL = `${config.baseUrl}/api/lms/courses/learning-outcome/${outComeId}/assessment-criteria`
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(
        id
          ? {
              criteria: action.params.criteria,
              learning_outcome_id: +outComeId,
              id,
            }
          : {
              criteria: action.params.criteria,
              learning_outcome_id: +outComeId,
            }
      ),
    })

    if (res.data.id) {
      if (typeof question === 'object' && Array.isArray(questions)) {
        const index = questions.findIndex((item) => item.id === question.id)
        if (index !== -1) {
          questions[index].assessment_criteria_id = res.data.id
          yield put(actions.setQuestions(questions))
        }

        yield put(
          actions.createQuiz({
            questions: questions,
            idCourse: get(slideDetails, 'channel_id', ''),
            name: get(slideDetails, 'name', ''),
          })
        )
      }
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onDeleteAssessment(action) {
  const selectedSlide = yield select((state) =>
    makeSelectSelectedSlide()(state)
  )
  const selectedCourse = yield select((state) => makeSelectCourse()(state))
  let requestURL = `${config.baseUrl}/api/lms/courses/assessment-criteria/${action.params}`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'DELETE',
    })

    if (get(selectedSlide, 'id') && get(selectedCourse, 'id')) {
      yield put(loadSlideDetails({ id: selectedSlide.id }))
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}
function* onLoadAttachment(action) {
  const selectedSlide = yield select((state) =>
    makeSelectSelectedSlide()(state)
  )
  const slideId = get(selectedSlide, 'id', '')
  let requestURL = `${config.baseUrl}/api/lms/courses/slides/${slideId}/attachment`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(actions.loadAttachmentSuccess(res.data))
  } catch (err) {
    console.log('err', err)
  }
}

function* onCreateAttachment(action) {
  const selectedSlide = yield select((state) =>
    makeSelectSelectedSlide()(state)
  )
  const slideId = get(selectedSlide, 'id', '')
  let requestURL = `${config.baseUrl}/api/lms/courses/slides/${slideId}/attachment`
  yield put(showGlobalLoading())
  try {
    yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify({
        name: get(action, 'params.name', ''),
        attachment: get(action, 'params.attachment', ''),
        is_attachment: true,
      }),
    })

    yield put(actions.loadAttachment())

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onDeleteAttachment(action) {
  const needToLoad = action.needToLoad
  let requestURL = `${config.baseUrl}/api/lms/courses/slide-attachment/${action.params}`
  if (needToLoad) yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'DELETE',
    })

    if (needToLoad) {
      yield put(actions.loadAttachment())
    }

    if (needToLoad) yield put(hideGlobalLoading())
  } catch (err) {
    if (needToLoad) yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onLoadZooms(action) {
  let requestURL = `${config.baseUrl}/api/notifications/zooms`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (res.data) {
      yield put(actions.loadZoomsSuccess(res.data))
    }
    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onDeleteScheduleItem(action) {
  const selectedSlide = yield select((state) =>
    makeSelectSelectedSlide()(state)
  )
  const course = yield select((state) => makeSelectCourse()(state))
  const idCourse = get(course, 'id', '')
  let requestURL = `${config.baseUrl}/api/lms/courses/channels/${idCourse}/slides`
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify({
        ...selectedSlide,
        old_schedule_ids: [action.params],
      }),
    })
    const successId = get(res, 'data.id', null)

    if (successId) {
      let isAdmin = true
      yield put(loadSlides(idCourse, isAdmin, successId))
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onLoadListUser(action) {
  const current = action.params.current || ''
  const superAdmin = action.params.superAdmin || false
  const search = action.params.search

  let requestURL = `${config.baseUrl}/api/users?page=${current}&order_by=id%20desc`

  if (superAdmin) {
    requestURL = `${
      config.baseUrl
    }/api/users?page=${current}&per_page=15&order_by=id%20asc&group_ids=${
      process.env.REACT_APP_SALES_GROUP_ID || '19'
    }&group_ids=3`
  }

  const regex = /^\w+([\.+_-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  if (search) {
    if (regex.test(search.trim())) {
      const params = stringify({ email: search.toLocaleLowerCase() || '' })
      requestURL = `${config.baseUrl}/api/users?${params}`
    } else {
      const params = stringify({
        name: search || '',
        per_page: 1000,
        ignore_per_page: true,
      })
      requestURL = `${config.baseUrl}/api/users?${params}`
    }
  }

  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data)) {
      yield put(actions.loadListUserSuccess(res.data))
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onGetUserData(action) {
  let requestURL = `${config.baseUrl}/api/users?user_id=${action.params}`
  const needToUpdate = action.needToUpdate
  const needToIgnoreCompany = action.needToUpdate
  if (needToIgnoreCompany) {
    requestURL += '&ignore_company=true'
  }
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data) && res.data[0]) {
      yield put(actions.getUserDataSuccess(res.data[0]))
      if (needToUpdate) {
        yield put(showFormUserModal(res.data[0]))
      }
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onGetUserProfile(action) {
  let requestURL = `${config.baseUrl}/api/users/${action.params}/profiles`
  const domain = yield select((state) => makeSelectDomain()(state))
  yield put(showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data)) {
      yield put(actions.getUserProfileSuccess(getProfile(domain?.id, res.data)))
    } else {
      yield put(actions.getUserProfileSuccess(null))
    }

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}

function* onGetListScheduleByEmail(action) {
  const email = action.params.email || ''
  const requestURL = `${config.baseUrl}/api/users/${email}/schedules`
  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(actions.getListScheduleByEmailSuccess(res.data))
    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    console.log('err', err)
    yield put(modalActions.hideGlobalLoading())
  }
}

function* onAddScheduleToUser(action) {
  const email = action.params.email || ''
  const schedule_id = action.params.schedule_id
  const channel_id = action.params.channel_id
  const id = action.params.id
  const requestURL = `${config.baseUrl}/api/users/schedules`
  yield put(modalActions.showGlobalLoading())
  const body = { email, schedule_id, channel_id }
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(id ? { ...body, id } : body),
    })

    yield put(actions.getListScheduleByEmail({ email }))
    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    console.log('err', err)
    yield put(modalActions.hideGlobalLoading())
  }
}

function* onDeleteScheduleOfUser(action) {
  const email = action.params.email || ''
  const id = action.params.id
  // /users/schedules/{id}
  const requestURL = `${config.baseUrl}/api/users/schedules/${id}`
  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'DELETE',
    })

    yield put(actions.getListScheduleByEmail({ email }))
    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    console.log('err', err)
    yield put(modalActions.hideGlobalLoading())
  }
}

function* onSetRemarksAnswer(action) {
  const schedule = action.params.schedule
  const id = action.params.id
  const remark = action.params.remark

  const requestURL = `${config.baseUrl}/api/lms/courses/slides/answers/${id}`
  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PATCH',
      body: JSON.stringify({ remark }),
    })

    if (schedule) {
      yield put(actions.loadListUserAnswer(get(schedule, 'value', '')))
      showSuccess('Add Remarks Success')
    }
    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    console.log('err', err)
    yield put(modalActions.hideGlobalLoading())
  }
}

function* onGetUserByCourseAndSchedule(action) {
  const schedule = action.params.schedule
  const id = action.params.id

  const requestURL = `${
    config.baseUrl
  }/api/lms/courses/channels/${id}/partners?schedule_id=${get(
    schedule,
    'value',
    0
  )}&per_page=1000&ignore_per_page=true`

  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (res.data) {
      yield put(actions.getUserByCourseAndScheduleSuccess(res.data))
    }

    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    console.log('err', err)
    yield put(modalActions.hideGlobalLoading())
  }
}

function* onGetLearningOutComeByUser(action) {
  const user_id = action.params.user_id
  const channel_id = action.params.channel_id

  const requestURL = `${config.baseUrl}/api/learning-outcome/users/${user_id}?channel_id=${channel_id}&order_by=id%20asc`

  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (res.data) {
      yield put(actions.getLearningOutComeByUserSuccess(res.data))
    }

    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    console.log('err', err)
    yield put(modalActions.hideGlobalLoading())
  }
}

function* onSetCompetentLearningOutcome(action) {
  const outcomeId = action.params.outcomeId
  const userId = action.params.userId
  const competent = action.params.competent
  const remark = action.params.remark || ''
  const id = action.params.id || null
  const channel_id = action.params.channel_id || ''
  const type = action.params.type || ''
  const requestURL = `${config.baseUrl}/api/learning-outcome/${outcomeId}/users`
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))

  const body = {
    user_id: userId,
    competent,
    channel_id,
    remark,
    type,
    create_uid: get(currentUser, 'uid', 0),
  }

  if (!remark) {
    delete body.remark
  }
  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(id ? { ...body, id } : body),
    })

    yield put(actions.getLearningOutComeByUser({ user_id: userId, channel_id }))

    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    console.log('err', err)
    yield put(modalActions.hideGlobalLoading())
  }
}

function* onGetListSetOutcomeStudents(action) {
  const listUid = action.params.listUid
  const channel_id = action.params.channel_id
  const requestURL = `${config.baseUrl}/api/learning-outcome/users?channel_id=${channel_id}${listUid}&per_page=1000&ignore_per_page=true`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (res.data) {
      yield put(actions.getListSetOutcomeStudentsSuccess(res.data))
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onAddOutcomeCourse(action) {
  const channel_id = action.params.channel_id
  const user_id = action.params.user_id
  const learning_outcome = action.params.learning_outcome || ''
  const assessment_criteria = action.params.assessment_criteria || ''
  const type = action.params.type || ''
  const remark = action.params.remark || ''
  const allocated_duration = action.params.allocated_duration || ''
  const start_time = action.params.start_time || ''
  const end_time = action.params.end_time || ''
  const competent = action.params.competent || ''
  const id = action.params.id || ''
  const body = {
    learning_outcome,
    assessment_criteria,
    type,
    id,
  }

  if (!id) {
    delete body.id
  }

  const requestURL = `${config.baseUrl}/api/learning-outcome/custom-channels/${channel_id}/users`
  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(body),
    })

    if (res.data && res.data.id) {
      yield put(
        actions.setListOutcomeCustom({
          remark,
          competent,
          user_id,
          channel_id,
          learning_outcome_channel_custom_id: res.data.id,
          allocated_duration,
          end_time,
          start_time,
        })
      )
      yield put(
        actions.getListOutcomeStudent({
          channel_id,
          user_id,
        })
      )
    }
    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    yield put(modalActions.hideGlobalLoading())
    console.log('err', err)
  }
}

function* onGetListOutcomeStudent(action) {
  const channel_id = action.params.channel_id
  const requestURL = `${config.baseUrl}/api/learning-outcome/custom-channels?order_by=id%20asc&channel_id=${channel_id}`

  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data)) {
      const data = res.data.map((item) => ({ ...item, is_custom: true }))
      yield put(actions.getListOutcomeStudentSuccess(data))
    }

    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    yield put(modalActions.hideGlobalLoading())
    console.log('err', err)
  }
}

function* onSetOutcomeCustom(action) {
  const channel_id = action.params.channel_id
  const user_id = action.params.user_id
  const id = action.params.id || ''
  const remark = action.params.remark || ''
  const competent = action.params.competent
  const learning_outcome_channel_custom_id =
    action.params.learning_outcome_channel_custom_id
  const allocated_duration = action.params.allocated_duration || ''
  const start_time = action.params.start_time || ''
  const end_time = action.params.end_time || ''
  const currentUser = yield select((state) => makeSelectCurrentUser()(state))

  const body = {
    user_id,
    remark,
    competent,
    channel_id,
    learning_outcome_channel_custom_id,
    id,
    create_uid: get(currentUser, 'uid', 0),
    allocated_duration,
    start_time,
    end_time,
  }

  if (!id) {
    delete body.id
  }

  if (!competent && competent !== false) {
    delete body.competent
  }

  const requestURL = `${config.baseUrl}/api/learning-outcome/custom-channels/users`

  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(body),
    })

    yield put(actions.getListSetOutcomeCustom({ channel_id }))

    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    yield put(modalActions.hideGlobalLoading())
    console.log('err', err)
  }
}

function* onGetListSetOutcomeCustom(action) {
  const channel_id = action.params.channel_id
  const requestURL = `${config.baseUrl}/api/learning-outcome/custom-channels/users?order_by=id%20asc&channel_id=${channel_id}&per_page=10000&ignore_per_page=true`

  yield put(modalActions.showGlobalLoading())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    if (Array.isArray(res.data)) {
      const data = res.data.map((item) => ({ ...item, is_custom: true }))
      yield put(actions.getListSetOutcomeCustomSuccess(data))
    }

    yield put(modalActions.hideGlobalLoading())
  } catch (err) {
    yield put(modalActions.hideGlobalLoading())
    console.log('err', err)
  }
}

function* onDeleteCustomOutcome(action) {
  const id = action.params.id
  let requestURL = `${config.baseUrl}/api/learning-outcome/custom-channels/${id}`
  yield put(showGlobalLoading())
  try {
    yield call(request, requestURL, {
      method: 'DELETE',
    })

    yield put(hideGlobalLoading())
  } catch (err) {
    yield put(hideGlobalLoading())
    console.log('err', err)
  }
}
export default function* adminSaga() {
  yield takeLatest(constants.LOAD_TAGS, onLoadTags)
  yield takeLatest(constants.CREATE_QUIZ, onCreateQuiz)
  yield takeLatest(constants.ADD_SCHEDULE, onAddSchedule)
  yield takeLatest(constants.GET_SCHEDULE, onGetSchedules)
  yield takeLatest(constants.DELETE_SCHEDULE, onDeleteSchedule)
  yield takeLatest(constants.CREATE_COURSE, onCreateCourse)
  yield takeLatest(constants.CREATE_LESSON, onCreateLesson)
  yield takeLatest(constants.SAVE_ZOOM_LINK, onSaveZoomLink)
  yield takeLatest(constants.CREATE_SECTION, onCreateSection)
  yield takeLatest(constants.LOAD_SLIDE_TAGS, onLoadSlideTags)
  yield takeLatest(constants.LOAD_COURSE_TYPE, onLoadCourseType)
  yield takeLatest(constants.CREATE_ZOOM_LINK, onCreateZoomLink)
  yield takeLatest(constants.SET_GLOBAL_COURSE, onSetGlobalCourse)
  yield takeLatest(constants.GET_ACCOUNT_STRIPE, onGetAccountStripe)
  yield takeLatest(constants.SET_ANSWER_COMPETENT, onSetAnswerCompetent)
  yield takeLatest(constants.LOAD_LIST_USER_ANSWER, onLoadListUserAnswer)
  yield takeLatest(constants.MARK_ANSWER_COMPETENT, onMarkAnswerCompetent)
  yield takeLatest(constants.CREATE_ACCOUNT_STRIPE, onCreateAccountStripe)
  yield takeLatest(constants.DELETE_COURSE, onDeleteCourse)
  yield takeLatest(constants.DELETE_LESSON, onDeleteLesson)
  yield takeLatest(constants.LOAD_ASSESSMENT, onLoadAssessment)
  yield takeLatest(constants.CREATE_ASSESSMENT, onCreateAssessment)
  yield takeLatest(constants.DELETE_ASSESSMENT, onDeleteAssessment)
  yield takeLatest(constants.LOAD_ATTACHMENT, onLoadAttachment)
  yield takeLatest(constants.CREATE_ATTACHMENT, onCreateAttachment)
  yield takeLatest(constants.DELETE_ATTACHMENT, onDeleteAttachment)
  yield takeLatest(constants.DELETE_LEARNING_OUTCOME, onDeleteLearningOutcome)
  yield takeLatest(constants.CREATE_LEARNING_OUTCOME, onCreateLearningOutcome)
  yield takeLatest(constants.LOAD_ZOOMS, onLoadZooms)
  yield takeLatest(constants.DELETE_SCHEDULE_ITEM, onDeleteScheduleItem)
  yield takeLatest(constants.LOAD_LIST_USER, onLoadListUser)
  yield takeLatest(constants.GET_USER_DATA, onGetUserData)
  yield takeLatest(constants.GET_USER_PROFILE, onGetUserProfile)
  yield takeLatest(constants.DELETE_SCHEDULE_OF_USER, onDeleteScheduleOfUser)
  yield takeLatest(constants.ADD_OUTCOME_COURSE, onAddOutcomeCourse)
  yield takeLatest(constants.SET_LIST_OUTCOME_CUSTOM, onSetOutcomeCustom)
  yield takeLatest(constants.GET_LIST_OUTCOME_STUDENT, onGetListOutcomeStudent)
  yield takeLatest(
    constants.GET_LIST_SET_OUTCOME_CUSTOM,
    onGetListSetOutcomeCustom
  )
  yield takeLatest(
    constants.GET_LIST_SCHEDULE_BY_EMAIL,
    onGetListScheduleByEmail
  )
  yield takeLatest(constants.ADD_SCHEDULE_TO_USER_EMAIL, onAddScheduleToUser)
  yield takeLatest(
    constants.LOAD_LIST_LEARNING_OUTCOME,
    onLoadListLearningOutcome
  )
  yield takeLatest(constants.SET_REMARKS_ANSWER, onSetRemarksAnswer)
  yield takeLatest(
    constants.GET_USER_BY_COURSE_AND_SCHEDULE,
    onGetUserByCourseAndSchedule
  )
  yield takeLatest(
    constants.GET_LEARNING_OUTCOME_BY_USER,
    onGetLearningOutComeByUser
  )
  yield takeLatest(
    constants.SET_COMPETENT_LEARNING_OUTCOME,
    onSetCompetentLearningOutcome
  )
  yield takeLatest(
    constants.GET_LIST_SET_OUTCOME_STUDENTS,
    onGetListSetOutcomeStudents
  )
  yield takeLatest(constants.LOAD_PAYMENT_STATUS, onLoadPaymentStatus)
  yield takeLatest(constants.DELETE_CUSTOM_OUTCOME, onDeleteCustomOutcome)
}
