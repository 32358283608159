import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { CloseCircleFilled } from '@ant-design/icons'
import { getUserAnswerTimes, resetQuiz } from '../../utils/hooks'
import { get } from 'lodash'
import config from '../../global-config'
import { getAccessToken } from '../../utils/request'
import { markAnswerCompetent } from '../../container/Admin/actions'
import { loadUserAnswer } from '../../container/Home/actions'
import * as actions from '../../container/Home/actions'

const FailPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 20px;
  border-top: 1px solid #d0cad8;

  .complete-icon {
    margin-top: 100px;
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }
  .complete-title {
    padding-top: 18px;
    font-size: 28px;
    font-weight: 600;
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
  .complete-desc {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  .complete-input {
    font-size: 18px;
    font-weight: 500;
    color: #77838f;
  }
  .complete-value {
    font-size: 18px;
    font-weight: 500;
  }
`

const FailPage = ({ slideDetails, allAnswer }) => {
  const dispatch = useDispatch()
  const updateUserAnswer = async (userId, questionId, body) => {
    try {
      const requestUrl = `${config.baseUrl}/api/users/${userId}/questions/${questionId}/answers`
      const res = await fetch(requestUrl, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      await res.json()
    } catch (error) {}
  }
  const handleResetQuiz = async () => {
    const requests = []
    for (let i = 0; i < allAnswer.length; i++) {
      if (allAnswer[i].type === '') {
        const userId = allAnswer[i].user_id
        const question_id = allAnswer[i].question_id
        const body = { ...allAnswer[i], value: '', competent: null }
        delete body.user
        requests.push(updateUserAnswer(userId, question_id, body))
      }
    }

    await Promise.all(requests)

    const userId = get(allAnswer, `[0].user.id`)
    const userAnswerTimes = await getUserAnswerTimes(userId)
    const selectedAnswerTime =
      Array.isArray(userAnswerTimes) &&
      userAnswerTimes.find((item) => item.slide_id === get(slideDetails, 'id'))

    await resetQuiz(
      userId,
      get(slideDetails, 'id'),
      get(selectedAnswerTime, 'id')
    )
    dispatch(
      markAnswerCompetent({
        user_id: userId,
        slide_id: get(allAnswer, '[0].slide_id', ''),
        competent: null,
        percent_quiz_pass: '',
        total_passed: 0,
      })
    )
    dispatch(actions.setSelectedSlide(slideDetails))
    dispatch(loadUserAnswer())
    localStorage.removeItem('countdownDeadline')
  }

  return (
    <FailPageWrapper>
      <div className="complete-icon">
        <CloseCircleFilled style={{ color: 'red', fontSize: 89 }} />
      </div>
      <div className="complete-title" style={{ textAlign: 'center' }}>
        You have failed this quiz!
      </div>
      {slideDetails?.quiz_pass_rate > 0 && (
        <div
          className="complete-desc"
          style={{ paddingTop: 20, textAlign: 'center', fontWeight: 'bold' }}
        >
          Grade - {slideDetails?.quiz_pass_rate}%
        </div>
      )}

      <div
        className="complete-desc"
        style={{ paddingTop: 30, textAlign: 'center' }}
      >
        <button
          className="tertiary-btn"
          type="button"
          style={{ width: 135 }}
          onClick={() => handleResetQuiz()}
        >
          <span style={{ fontWeight: 'bold', fontSize: 15 }}>Retry now</span>
        </button>
      </div>
    </FailPageWrapper>
  )
}
export default FailPage
