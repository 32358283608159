import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import ReactPlayer from 'react-player'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { parse } from 'query-string'
import { get, isEmpty } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import Measure from 'react-measure'
import throttle from 'lodash/throttle'
import classNames from 'classnames'
import images from 'Themes/Images'
import initialScorm from 'component/ChannelDetails/scorm'
import parseHtml, { domToReact } from 'html-react-parser'
import config from 'src/global-config'
import QuizLesson from '../QuizLesson'
import SwitchPagePdf from '../SwitchPagePdf'
import Attachments from './components/Attachments'
import ChannelHead from './components/ChannelHead'
import * as selectors from 'container/Home/selectors'
import * as actions from 'container/Home/actions'
import { useKeyPress } from 'utils/hooks'
import { SLIDE_TYPE } from 'utils/constants'
import { showError } from 'utils/notification'
import { getDescriptionData } from 'utils/helper'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import {
  makeSelectAnswerTime,
  makeSelectDomain,
} from '../../container/Home/selectors'
import { parseSafe } from 'utils/helper'
import './styles.scss'
import dayjs from 'dayjs'
import { CKEditorWrapper } from '../../container/CMS/screens/Article'

const PdfRender = React.memo(
  ({
    pdf,
    onDocumentLoadSuccess,
    setPageWidth,
    setPageHeight,
    fitHorizontal,
    wrapperWidth,
    wrapperHeight,
    pageNumber,
    setWrapperDimensions,
    onDocumentLoadFailed,
    error,
  }) => {
    return (
      pdf && (
        <Measure
          bounds
          onResize={(contentRect) =>
            setWrapperDimensions(
              Math.ceil(contentRect.bounds.width),
              Math.ceil(contentRect.bounds.height)
            )
          }
        >
          {({ measureRef }) => (
            <div className="pdf-wrapper" ref={measureRef}>
              {error ? (
                <p>No content uploaded yet</p>
              ) : (
                <Document
                  file={pdf}
                  onLoadError={onDocumentLoadFailed}
                  onLoadSuccess={onDocumentLoadSuccess}
                  externalLinkTarget="_blank"
                  className="pdf-custom"
                >
                  <Page
                    key={pdf}
                    onLoadSuccess={(page) => {
                      setPageWidth(page.width)
                      setPageHeight(page.height)
                    }}
                    pageNumber={pageNumber}
                    className="page-custom"
                    width={fitHorizontal ? wrapperWidth : null}
                    height={!fitHorizontal ? wrapperHeight : null}
                  />
                </Document>
              )}
            </div>
          )}
        </Measure>
      )
    )
  }
)

const ChannelDetails = ({
  pageNumber,
  setPageNumber,
  slides,
  preview,
  id,
  previews,
  userLogByCourse,
}) => {
  const [hideDocs, setHideDocs] = useState(false)
  const [height, setHeight] = useState(null)
  const [numPages, setNumPages] = useState(null)
  const [activePDF, setActivePDF] = useState(false)
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const [pageWidth, setPageWidth] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  const [listImage, setListImage] = useState([])
  const [currentImage, setCurrentImage] = useState(0)
  const [error, setError] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const history = useHistory()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { search } = useLocation()
  const { quiz } = parse(search)
  const domain = useSelector(makeSelectDomain())
  const course = useSelector(selectors.makeSelectCourse())

  const slide = useSelector(selectors.makeSelectSelectedSlide())
  const slideDetails = useSelector(selectors.makeSelectSlideDetails())
  const currentUser = useSelector(makeSelectCurrentUser())
  const description = getDescriptionData(get(slide, 'description'))
  const arrowLeftPress = useKeyPress('ArrowLeft')
  const arrowRightPress = useKeyPress('ArrowRight')
  const answerTime = useSelector(makeSelectAnswerTime())
  const question = get(slideDetails, 'questions', [])
  const isLessonHasQuiz = Array.isArray(question) && question.length !== 0

  const hideAdditionalInfo = useMemo(() => {
    return (
      description === '' ||
      (!isEmpty(description) && description?.blocks?.length === 0)
    )
  }, [description])

  const attachment = get(slideDetails, 'attachments[0]', {})
  const pdf =
    slideDetails && slideDetails.document_url
      ? slideDetails.document_url
      : `${config.baseUrl}/web/content/${get(attachment, 'res_model')}/${get(
          attachment,
          'res_id'
        )}/datas`

  const file = useMemo(() => ({ url: pdf }), [pdf])

  const scormVersion = get(slideDetails, 'scorm_version', '')
  const scormFile = get(slideDetails, 'filename', '')
  const isScormSlide = get(slideDetails, 'slide_type', '') === SLIDE_TYPE.SCORM
  const div = useCallback(
    (node) => {
      if (node !== null && slideDetails) {
        setHeight(node.getBoundingClientRect().height)
      }
    },
    [slideDetails]
  )

  useEffect(() => {
    if (slideDetails) {
      setListImage(parseSafe(get(slideDetails, 'image_url', '')))
      setError(false)
    }
  }, [slideDetails])

  useEffect(() => {
    if (hideDocs && slideDetails && slideDetails.id === 2456) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [hideDocs, slideDetails])

  useEffect(() => {
    setHideDocs(hideAdditionalInfo)
  }, [hideAdditionalInfo, slide])

  const fitHorizontal = useMemo(() => {
    const wRatio = pageWidth / wrapperWidth
    const hRatio = pageHeight / wrapperHeight
    if (wRatio < hRatio) {
      return false
    }
    return true
  }, [pageHeight, pageWidth, wrapperHeight, wrapperWidth])

  const setWrapperDimensions = useCallback(
    throttle((w, h) => {
      setWrapperWidth(w)
      setWrapperHeight(h)
    }, 500),
    []
  )

  useEffect(() => {
    if (activePDF && arrowLeftPress) {
      handlePreviousPage()
    }
    if (activePDF && arrowRightPress) {
      handleNextPage()
    }
  }, [activePDF, arrowLeftPress, arrowRightPress])

  useEffect(() => {
    if (slide) {
      dispatch(actions.getAnswerTime())
      setCurrentImage(0)
    }
  }, [slide])

  useEffect(() => {
    if (slide && slide.show_quiz_only) {
      history.push(
        `/course/channel/${slide.channel_id}?quiz=true${
          preview ? '&preview=true' : ''
        }`
      )
    }
  }, [slide])

  useEffect(() => {
    if (isScormSlide && scormFile && scormVersion) initialScorm(scormVersion)
  }, [scormVersion, scormFile, isScormSlide])

  useEffect(() => {
    if (quiz) {
      dispatch(actions.setSelectedQuiz(get(slideDetails, 'questions[0]', null)))
    }
  }, [quiz, slideDetails])

  const handlePreviousSlide = (isQuiz) => {
    if (slide) {
      const channelID = get(slide, 'channel_id')
      const index = slide?.sequence || 0
      if (index > -1 && slides[index]) {
        const currentIndexSlide = slides[index].items.findIndex(
          (i) => i.id === slide.id
        )
        if (isQuiz) {
          return history.push(`/course/channel/${channelID}`)
        }

        if (currentIndexSlide !== 0) {
          const previousSlide = slides[index].items[currentIndexSlide - 1]
          if (
            Array.isArray(previousSlide.questions) &&
            previousSlide.questions.length > 0
          ) {
            dispatch(actions.setSelectedSlide(previousSlide))
            history.push(
              `/course/channel/${channelID}?quiz=true${
                preview ? '&preview=true' : ''
              }`
            )
          } else {
            dispatch(actions.setSelectedSlide(previousSlide))
          }
        } else {
          if (slides[index - 1]) {
            const previousSlide =
              slides[index - 1].items[slides[index - 1].items.length - 1]
            if (previousSlide?.questions?.length > 0 && !isQuiz) {
              history.push(
                `/course/channel/${channelID}?quiz=true${
                  preview ? '&preview=true' : ''
                }`
              )
            }
            dispatch(actions.setSelectedSlide(previousSlide))
          }
        }
      }
    }
  }

  const handleNextSlide = (isQuiz) => {
    if (slide) {
      const channelID = get(slide, 'channel_id')
      const index = slide?.sequence || 0
      if (index > -1 && slides[index]) {
        const currentIndexSlide = slides[index].items.findIndex(
          (i) => i.id === slide.id
        )
        if (currentIndexSlide !== slides[index].items.length - 1) {
          const currentSlide = slides[index].items[currentIndexSlide]
          const nextSlide = slides[index].items[currentIndexSlide + 1]

          if (currentSlide && currentSlide?.questions.length > 0 && !isQuiz) {
            history.push(
              `/course/channel/${channelID}?quiz=true${
                preview ? '&preview=true' : ''
              }`
            )
          } else {
            if (nextSlide && nextSlide.blocked_lesson) {
              return
            }
            dispatch(actions.setSelectedSlide(nextSlide))
            history.push(`/course/channel/${channelID}`)
          }
        } else if (slides[index + 1] && slides[index + 1].items[0]) {
          if (slide?.questions?.length > 0 && !isQuiz) {
            return history.push(
              `/course/channel/${channelID}?quiz=true${
                preview ? '&preview=true' : ''
              }`
            )
          }
          const nextSlide = slides[index + 1].items[0]
          if (nextSlide && nextSlide.blocked_lesson) {
            return
          }
          history.push(`/course/channel/${channelID}`)
          dispatch(actions.setSelectedSlide(nextSlide))
        }
      }
    }
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setActivePDF(true)
    setNumPages(numPages)
    setPageNumber(1)
  }

  const onDocumentLoadFailed = () => {
    setError(true)
    setActivePDF(false)
  }
  const handleSetComplete = async () => {
    const params = {
      end_date: dayjs(),
      completed: true,
    }

    if (isLessonHasQuiz) {
      if (!Array.isArray(answerTime)) {
        return null
      }
      const indexSlide = answerTime.findIndex(
        (item) => item.slide_id === slideDetails?.id
      )
      const isComplete = get(answerTime, `[${indexSlide}].end_date`, false)
      if (!isComplete) {
        history.push(
          `/course/channel/${slide.channel_id}?quiz=true${
            preview ? '&preview=true' : ''
          }`
        )
        return showError('Please press Start Quiz button to begin.')
      }

      if (currentUser) {
        dispatch(actions.setPreviewComplete(params))
      } else {
        showError('You need to login to set complete')
      }
    } else {
      if (currentUser) {
        dispatch(actions.setPreviewComplete(params))
      } else {
        showError('You need to login to set complete')
      }
    }
  }

  const handleNextPage = () => {
    if (pageNumber !== numPages) {
      setPageNumber(pageNumber + 1)
    } else if (pageNumber === numPages) {
      handleNextSlide(false)
    }
  }

  const handlePreviousPage = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  const handleNextImage = () => {
    if (currentImage >= listImage.length - 1) return handleNextSlide(false)
    setCurrentImage(currentImage + 1)
  }

  const handlePreviousImage = () => {
    if (currentImage === 0) return
    setCurrentImage(currentImage - 1)
  }

  const renderMainContent = () => {
    let content = null
    if (slideDetails) {
      // eslint-disable-next-line default-case
      switch (slideDetails.slide_type) {
        case SLIDE_TYPE.INFOGRAPHIC:
          const img = slideDetails.attachments.find(
            (item) => item.name === 'image_1920'
          )
          const lessonImg = img
            ? `${config.imageURL}/${img.res_model}/${img.res_id}/${img.res_field}`
            : get(slideDetails, 'image_url', '')

          if (Array.isArray(listImage) && listImage.length > 0) {
            content = (
              <>
                <div
                  style={{
                    cursor: 'pointer',
                    paddingBottom: 14,
                    textAlign: 'right',
                  }}
                >
                  <span onClick={handlePreviousImage}>Previous</span> |{' '}
                  <span onClick={handleNextImage}>Next</span>
                </div>
                <img
                  className="channel__img"
                  src={get(listImage, `[${currentImage}].data_url`, '') + '=s0'}
                  alt={get(listImage, `[${currentImage}].name`, '')}
                />
              </>
            )
          } else {
            content = <img className="channel__img" src={lessonImg} alt="" />
          }

          break
        case SLIDE_TYPE.STREAM:
          content = (
            <>
              <iframe
                width="100%"
                src={slideDetails.url}
                className="iframe__wrapper"
                frameBorder="0"
              />
            </>
          )

          break
        case SLIDE_TYPE.VIDEO:
          content = (
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={slideDetails.url}
                width="100%"
                height="100%"
                controls={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                    },
                  },
                }}
              />
            </div>
          )
          break
        case SLIDE_TYPE.PRESENTATION:
          const prefix = pdf.slice(pdf.length - 4)
          const isPPFile = ['.ppt', 'pptx'].includes(prefix)

          content = isMobile ? (
            isPPFile ? (
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${pdf}`}
                width="100%"
                height="300px"
                frameBorder="0"
                title="slides"
              ></iframe>
            ) : (
              <>
                {activePDF && (
                  <SwitchPagePdf
                    activePDF={activePDF}
                    pageNumber={pageNumber}
                    numPages={numPages}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                  />
                )}
                {error ? (
                  <p>No content uploaded yet</p>
                ) : (
                  <Document
                    file={file}
                    onLoadError={onDocumentLoadFailed}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="pdf-custom"
                    externalLinkTarget="_blank"
                  >
                    <Page
                      pageNumber={pageNumber}
                      className="page-custom"
                      width={windowWidth - 40}
                    />
                  </Document>
                )}
              </>
            )
          ) : isPPFile ? (
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${pdf}`}
              width="100%"
              height="600px"
              frameBorder="0"
              title="slides"
            ></iframe>
          ) : (
            <>
              {activePDF && (
                <SwitchPagePdf
                  activePDF={activePDF}
                  pageNumber={pageNumber}
                  numPages={numPages}
                  handleNextPage={handleNextPage}
                  handlePreviousPage={handlePreviousPage}
                />
              )}
              <PdfRender
                pdf={file}
                error={error}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                onDocumentLoadFailed={onDocumentLoadFailed}
                setPageWidth={setPageWidth}
                setPageHeight={setPageHeight}
                fitHorizontal={fitHorizontal}
                wrapperWidth={wrapperWidth}
                wrapperHeight={wrapperHeight}
                pageNumber={pageNumber}
                setWrapperDimensions={setWrapperDimensions}
              />
            </>
          )
          break

        case SLIDE_TYPE.SCORM:
          content = (
            <>
              {hideDocs && (
                <div
                  className="iframe__fullscreen__close"
                  onClick={() => setHideDocs(false)}
                >
                  <img src={images.header.tv} alt="" /> Exit Fullscreen
                </div>
              )}
              <iframe
                width="100%"
                src={scormFile}
                className={classNames('iframe__wrapper', {
                  iframe__fullscreen: hideDocs,
                })}
                frameBorder="0"
                title="iframe"
              />
            </>
          )
          break
      }
      return content
    }
  }

  return (
    <div>
      {quiz ? (
        slideDetails &&
        course && (
          <QuizLesson
            course={course}
            slideDetails={slideDetails}
            handlePreviousSlide={handlePreviousSlide}
            handleNextSlide={handleNextSlide}
            id={id}
            userLogByCourse={userLogByCourse}
            slides={slides}
          />
        )
      ) : (
        <div>
          {slideDetails && course ? (
            <div
              className="channel"
              style={{ minHeight: 'calc( 100vh + 244px )' }}
            >
              <div ref={div}>
                <ChannelHead
                  course={course}
                  slideDetails={slideDetails}
                  handleSetComplete={handleSetComplete}
                  handlePreviousSlide={handlePreviousSlide}
                  handleNextSlide={handleNextSlide}
                  setHideDocs={setHideDocs}
                  hideDocs={hideDocs}
                  isMobile={isMobile}
                  previews={previews}
                />
              </div>
              {!isMobile ? (
                <div
                  className="channel__body"
                  style={{ height: `calc(100% - ${height ? height : 84}px)` }}
                >
                  <div
                    className="channel__left"
                    style={{
                      width: hideDocs && '100%',
                      transition: 'all 0.3s',
                      overflow: 'hidden',
                      borderRight: '1px solid #d0cad8',
                    }}
                  >
                    {renderMainContent()}
                  </div>

                  {!hideDocs && (
                    <div className="channel__right" style={{ width: 300 }}>
                      <div
                        className="channel__content"
                        style={{ margin: '18px 20px!important' }}
                      >
                        <Attachments selectedSlide={slideDetails} />
                        <div className="channel__content_head">
                          Additional Notes
                        </div>

                        <div className="channel__desc">
                          <CKEditorWrapper>
                            {parseHtml(description)}
                          </CKEditorWrapper>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="channel__body channel__mobile__body">
                  <div
                    className="channel__left"
                    style={{
                      width: '100%',
                      transition: 'all 0.3s',
                      overflow: 'hidden',
                    }}
                  >
                    {renderMainContent()}
                  </div>

                  <div className="channel__right channel__right__mobile">
                    <Attachments selectedSlide={slideDetails} />
                    <div className="channel__content">
                      <div className="channel__content_head">
                        Additional Notes
                      </div>
                      <div className="channel_desc">
                        <CKEditorWrapper>
                          {parseHtml(description)}
                        </CKEditorWrapper>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="channel channel__empty-lesson">
              <h3>There are currently no lessons in this course.</h3>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ChannelDetails
