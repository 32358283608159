import { useState, useEffect, useRef } from 'react'
import config from 'src/global-config'
import { showSuccess } from 'utils/notification'
import { setCountUserImported } from '../container/Modal/actions'
import axiosInstance from 'utils/axiosInstance'
import { getUserRoles } from 'utils/userUtils'

export default function useComponentVisible(initialIsVisible, handleShow) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (typeof handleShow === 'function') {
        handleShow()
      } else {
        setIsComponentVisible(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible }
}

export const useClickOutside = (handelFunc, exceptRef) => {
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (exceptRef?.current) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !exceptRef.current.contains(event.target)
      ) {
        handelFunc()
      }
    } else {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.className.toString().startsWith('save-and-next') &&
        !event.target.className.toString().startsWith('btn btn-primary') &&
        !event.target.className.toString().startsWith('checked-grade') &&
        !event.target.className.toString().startsWith('quiz__head__check')
      ) {
        handelFunc()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref }
}

export const useFocusHooks = (initialActive, handelFunc) => {
  const [isActive, setIsActive] = useState(initialActive)
  const currentRef = useRef(null)
  const inputRef = useRef(null)

  const handleClickRef = (event) => {
    if (
      currentRef.current &&
      inputRef.current &&
      currentRef.current.contains(event.target)
    ) {
      inputRef.current.focus()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickRef, true)
    return () => {
      document.removeEventListener('click', handleClickRef, true)
    }
  }, [isActive])

  return { currentRef, inputRef, isActive, setIsActive }
}

export const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false)
  useEffect(() => {
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false)
      }
    }

    const downHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true)
      }
    }

    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [targetKey])

  return keyPressed
}

export const resetQuiz = async (userId, slide_id, id) => {
  const requestUrl = `${config.baseUrl}/api/users/${userId}/answer-times`
  try {
    await fetch(requestUrl, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ slide_id, start_date: null, end_date: null, id }),
    })

    showSuccess('Reset Quiz Success!')
  } catch (error) {
    showSuccess('Failed to reset quiz, please try again!')
  }
}

export const getUserAnswerTimes = async (userId) => {
  const requestURL = `${config.baseUrl}/api/users/${userId}/answer-times`
  try {
    const res = await fetch(requestURL, {
      method: 'GET',
    })
    const result = await res.json()
    if (Array.isArray(result.data)) {
      return result.data
    }
  } catch (err) {
    console.log('err', err)
  }
}

export const signUpUser = async (values, dispatch) => {
  const requestURL = `${config.baseUrl}/api/users/signup`
  const isAdmin = values.isAdmin
  const body = {
    name: values.name,
    login: values.login,
    mobile: values.mobile || '',
    additional_info: values.additional_info || '',
  }

  if (values.password) {
    body.password = values.password
  }
  if (values.id) {
    body.id = values.id
  }
  if (values.role) {
    body.group_id = values.role
  }
  try {
    const res = await fetch(requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    const result = await res.json()
    if (result.data?.id) {
      if (isAdmin) {
        await updateUserRole(result.data?.id, isAdmin)
      }
      dispatch(setCountUserImported())
      return result.data
    }
    return false
  } catch (err) {
    return false
  }
}

const updateUserRole = async (userID, isAdmin) => {
  try {
    await axiosInstance.post(`${config.baseUrl}/api/users/${userID}/group`, {
      group_id: getUserRoles({ admin: isAdmin }),
    })
  } catch (error) {
    console.log('err', error)
  }
}

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        // const nextValue = typeof value === 'string' ? value.trim() : value
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmounted)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}
