import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import images from 'Themes/Images'
import { makeSelectCurrentUser } from '../../container/Auth/selectos'

const CompletePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 20px;
  border-top: 1px solid #d0cad8;

  .complete-icon {
    margin-top: 100px;
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }
  .complete-title {
    padding-top: 18px;
    font-size: 28px;
    font-weight: 600;
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
  .complete-desc {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  .complete-input {
    font-size: 18px;
    font-weight: 500;
    color: #77838f;
  }
  .complete-value {
    font-size: 18px;
    font-weight: 500;
  }
`

const CompletePage = (slideDetails) => {
  const currentUser = useSelector(makeSelectCurrentUser())

  return (
    <CompletePageWrapper>
      <div className="complete-icon">
        <img src={images.complete} alt="" />
      </div>
      <div className="complete-title" style={{ textAlign: 'center' }}>
        You have completed this quiz!
      </div>
      {slideDetails?.slideDetails?.quiz_pass_rate > 0 && (
        <div
          className="complete-desc"
          style={{ paddingTop: 20, textAlign: 'center', fontWeight: 'bold' }}
        >
          Grade - {slideDetails?.slideDetails?.quiz_pass_rate}%
        </div>
      )}
      <div
        className="complete-desc"
        style={{ paddingTop: 30, textAlign: 'center' }}
      >
        IF REQUIRED PLEASE SCREENSHOT THIS COMPLETION PAGE AND SHOW
      </div>
      <div className="complete-desc">
        IT TO YOUR TRAINER ON THE DAY OF YOUR TRAINING
      </div>
      <div style={{ textAlign: 'center', paddingTop: 30 }}>
        <div className="complete-input">Full Name</div>
        <div className="complete-value">
          {currentUser?.name || currentUser?.partner?.name || ''}
        </div>
        <div className="complete-input">Email</div>
        <div className="complete-value">
          {currentUser?.username || currentUser?.login || ''}
        </div>
      </div>
    </CompletePageWrapper>
  )
}
export default CompletePage
