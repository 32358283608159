import React, { useState } from 'react'
import images from 'Themes/Images'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { Popover } from 'antd'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useClickOutside } from 'utils/hooks'
import {
  setSelectedSection,
  createSection,
  loadAttachmentSuccess,
  setQuestions,
} from '../../../../actions'
import { setSelectedSlide } from 'container/Home/actions'
import {
  loadSlideDetailsSuccess,
  loadSlidesSuccess,
} from 'container/Home/actions'
import useComponentVisible from 'utils/hooks'
import './styles.scss'
import { MenuOutlined } from '@ant-design/icons'

const SideBarList = ({
  slide,
  handleToggle,
  handleClickSlide,
  selectedSlide,
  slides,
  resetForm,
  setAddSection,
  handleClickQuiz,
  currentRef,
  isActive,
  setIsActive,
  setListSchedules,
  history,
  pathname,
  formik,
  lastIndex,
  saveBtnRef,
}) => {
  const dispatch = useDispatch()
  const [quizSelect, setQuizSelect] = useState(false)
  const [visible, setVisible] = useState(false)
  const [textBox, setTextBox] = useState(false)
  const [text, setText] = useState('')

  const {
    ref: popRef,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false)

  const handleAddLesson = () => {
    setListSchedules([])
    setAddSection(false)
    setVisible(false)
    setIsActive(!isActive)
    dispatch(loadAttachmentSuccess(null))
    dispatch(setSelectedSlide(null))
    dispatch(loadSlideDetailsSuccess(null))
    dispatch(setSelectedSection(slide))

    let hasNewLesson = false
    slides.forEach((item) => {
      const match = item.items.find((subItem) => subItem.id === 100)
      if (match && match.id === 100) {
        hasNewLesson = match
      }
    })

    if (hasNewLesson && hasNewLesson.id === 100) {
      resetForm()
      return
    }

    const indexSlide = slides.findIndex((item) => item.id === slide.id)

    if (indexSlide >= 0) {
      const fakeData = {
        id: 100,
        name: 'New Lesson',
        attachments: null,
        category_id: 125,
        channel_id: 48,
        create_date: '2020-08-22T07:28:06.370515Z',
        description: 'New Lesson',
        is_category: false,
        is_published: true,
        questions: [],
        slide_type: 'video',
        url: 'https://www.youtube.com/watch?v=Zzn9-ATB9aU',
        write_date: '2020-08-25T07:45:12.523828Z',
      }

      const newSlides = [...slides]
      newSlides[indexSlide].items = [
        ...slides[indexSlide].items,
        { ...fakeData },
      ]
      history.push(`${pathname}?route=lessons`)

      dispatch(loadSlidesSuccess(newSlides))
    }
    resetForm()
  }

  const handleAddSection = () => {
    setVisible(false)
    dispatch(setSelectedSection(slide))
    setTextBox(true)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && text) {
      handleAddSectionServer()
    }
  }

  const handleAddSectionServer = () => {
    const id = get(slide, 'channel_id')
    let payload = {
      name: text,
      is_category: true,
    }

    dispatch(createSection(payload, id))
    setTextBox(false)
    setText('')
  }

  const handleKeyPressLesson = (e) => {
    if (e.key === 'Enter' && formik.values.name) {
      formik.handleSubmit()
    }
  }

  const { ref } = useClickOutside(formik.handleSubmit, saveBtnRef)
  const { ref: sectionRef } = useClickOutside(handleAddSectionServer)
  return (
    <div>
      <div className="lessons-sidebar__block">
        {slide.is_category && (
          <div className="sidebar__title lessons-sidebar__title">
            <div style={{ display: 'flex' }}>
              {/* <img src={images.admin.menu_active_icon} alt="" /> */}
              <div style={{ margin: 'auto', fontSize: '10px' }}>
                <MenuOutlined color="#667180" />
              </div>
              <div
                onClick={() => {
                  handleToggle(slide)
                }}
                style={{ marginLeft: 5 }}
              >
                {slide.name}
              </div>
            </div>
            <div
              onClick={() => {
                dispatch(setSelectedSection(slide))
                dispatch(setSelectedSlide(null))
                setAddSection(true)
              }}
              style={{ marginTop: 4 }}
            >
              <img
                src={images.admin.configuration}
                alt=""
                className="lessons-sidebar__edit"
              />
            </div>
          </div>
        )}
        <Droppable droppableId={slide.id.toString()} type="droppableLesson">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div
                className={`${
                  slide.is_toggle ? 'toggle sidebar__list' : 'sidebar__list'
                }`}
              >
                {slide.items.map((item, index) => (
                  <Draggable
                    draggableId={item.id.toString()}
                    key={item.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className={`sidebar__topic ${
                          selectedSlide &&
                          selectedSlide.id === item.id &&
                          !quizSelect
                            ? 'sidebar__topic-active'
                            : ''
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item.id}
                      >
                        {item.name === 'New Lesson' ? (
                          <div className="sidebar__topic-wrap">
                            <div className="sidebar__icon">
                              <img src={images.admin.menu_icon} alt="" />
                            </div>
                            <div
                              className="sidebar__topic__title"
                              style={{ width: '100%' }}
                            >
                              <input
                                ref={ref}
                                type="text"
                                className="input__section__add"
                                placeholder="Name this lesson"
                                onChange={formik.handleChange}
                                name="name"
                                value={formik.values.name}
                                onKeyPress={handleKeyPressLesson}
                                autoFocus
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="sidebar__topic-wrap"
                            onClick={() => {
                              window.scrollTo({
                                behavior: 'smooth',
                                top: 100,
                              })
                              handleClickSlide(item)
                              setQuizSelect(false)
                              setAddSection(false)
                              dispatch(setQuestions([]))
                              history.push(`${pathname}?route=lessons`)
                            }}
                          >
                            <div className="sidebar__icon">
                              <img src={images.admin.menu_icon} alt="" />
                            </div>
                            <div
                              className="sidebar__topic__title"
                              style={{ width: '100%' }}
                            >
                              {item.name}
                            </div>
                          </div>
                        )}

                        {Array.isArray(item.questions) &&
                          item.questions.length !== 0 && (
                            <div>
                              <li
                                className="sidebar__source"
                                onClick={() => {
                                  handleClickQuiz(item)
                                }}
                              >
                                <div className="sidebar__source__icon">
                                  <img src={images.header.poll_h} alt="" />
                                </div>
                                <div
                                  className={`sidebar__source__title ${
                                    selectedSlide &&
                                    selectedSlide.id === item.id &&
                                    quizSelect
                                      ? 'sidebar__source__title__active'
                                      : ''
                                  }`}
                                >
                                  Quiz {`(${item.questions.length} questions)`}
                                </div>
                              </li>
                            </div>
                          )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}

                {textBox ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 3 }}>
                      <img src={images.admin.menu_active_icon} alt="" />
                    </span>
                    <input
                      ref={sectionRef}
                      type="text"
                      className="input__section__add"
                      placeholder="Name this section"
                      onChange={(e) => setText(e.currentTarget.value)}
                      value={text}
                      onKeyPress={handleKeyPress}
                      autoFocus
                    />
                  </div>
                ) : (
                  <li className="sidebar__add">
                    <Popover
                      placement="top"
                      overlayClassName="popup_confirm"
                      content={
                        <div className="popup-cta-custom">
                          <div
                            className="popup-cta-custom__wrap"
                            onClick={handleAddSection}
                            style={{
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10,
                            }}
                          >
                            <div className="popup-cta-custom__text">
                              + Add a section
                            </div>
                          </div>
                          <div
                            className="popup-cta-custom__wrap"
                            onClick={handleAddLesson}
                            style={{
                              borderBottomLeftRadius: 10,
                              borderBottomRightRadius: 10,
                            }}
                            ref={currentRef}
                          >
                            <div className="popup-cta-custom__text">
                              + Add a lesson/quiz
                            </div>
                          </div>
                        </div>
                      }
                      trigger="click"
                      visible={isComponentVisible}
                    >
                      <div
                        ref={popRef}
                        className={`sidebar__cta ${
                          isComponentVisible || lastIndex
                            ? 'sidebar__cta-active'
                            : ''
                        }`}
                      >
                        <div
                          className="sidebar__cta__text"
                          onClick={() =>
                            setIsComponentVisible(!isComponentVisible)
                          }
                        >
                          Add
                        </div>
                      </div>
                    </Popover>
                  </li>
                )}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}

export default SideBarList
